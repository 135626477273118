<template>
  <div class="theme-market-container">
    <!-- <div class="banner">
      <div class="container grid-banner">
        <div class="circle"></div>
        <div style="z-index:1">
          <div class="subtitle6 primary">플랫폼파이 테마</div>
          <div class="h6 main margin-top-16">간단한 편집이 가능한 디자인 빌더를 제공하는<br>플랫폼 서비스 테마</div>
          <div class="body2 sub2 margin-top-20">내 서비스에 맞는 테마를 선택하고<br>
            간단하게 디자인을 커스텀하여 플랫폼 서비스를 시작해보세요!</div>
          <button class="button is-primary-light margin-top-28"
                  style="width:160px;height:40px" @click="routerPush('/platformfy_info')">테마 주요기능 보기</button>
        </div>
        <video class="video" width="100%" :autoplay="true" :loop="true" muted
               controls="true" controlsList="nodownload" oncontextmenu="return false">
          <source src="https://s3.ap-northeast-2.amazonaws.com/launchpack-storage/media/non-path/service_main.mp4" type="video/mp4">
        </video>
      </div>
    </div> -->
    <div class="flex-center title-container">
      <div class="text-center">
        <div class="sub3 margin-bottom-12">아이디어 테스트 단계</div>
        <div class="h4 main" ><span>1개월 단위로 플랫폼 서비스를 운영해보고 싶은 창업자를 위한 </span>
          <span class="primary">플랫폼파이 <br> 무료 체험해보세요!</span></div>
        <div class="body2 sub3 margin-top-12">블럭 단위로 디자인 편집을 할 수 있는 빌더가 포함되어 있어<br/>간단히 내 아이디어에 맞게 설정하고 런칭할 수 있어요.</div>
      </div>
    </div>
    <div class="margin-top-40">
      <div class="container">
        <div class="grid-theme">
          <card-product v-for="card in themes" :key="`card-${card.id}`" :card="card"></card-product>
        </div>

        <div class="margin-top-60">
          <div class="flex" style="gap:24px">
            <div class="item-survey">
              <div>
                <img src="/static/icon/emoticon_hmm.png" style="width:40px;height:40px;margin-bottom: 20px">
              </div>
              <div class="h6 margin-bottom-20">어떤 테마를 선택해야할지<br>어려우신가요?</div>
              <a href="https://moaform.com/q/PEF75O" target="_blank">
                <div class="button-black unselect">내 서비스에 맞는 테마찾기</div>
              </a>
            </div>
            <div class="item-inquiry">
              <div>
                <img src="/static/icon/ic_inquiry.png" style="width:40px;height:40px;margin-bottom: 20px">
              </div>
              <div class="h6 margin-bottom-20">찾는 테마가 없으시다면?<br>맞춤 플랫폼 제작!</div>
              <a href="https://launchpack.co.kr/prime" target="_blank">
                <div class="button-sub2 unselect">견적문의하기</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CardProduct from "../component/CardProduct";
  export default {
    name: "ThemeMarketPc",
    components: {
      CardProduct
    },
    created() {
      this.getData();
    },
    data() {
      return {
        themes: []
      }
    },
    methods: {
      getData() {
        let params = {
          category: 59,
          fields: 'id,name,simple_desc,price,img,partner.img,partner.name,category1,category2,preview_url,visit,rate,tags,theme_version',
          page_num: 1,
          page_length: 10,
          ordering: 'priority'
        };
        this.$axios.get('user/0/mapping/product', {params: params}).then(res => {
          this.themes = res.data.data;
        });
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .theme-market-container
    margin 74px 0 80px 0
  .title-container
    padding 80px 0 20px

  .banner
    background-color $gray4
    padding 40px 0
    overflow hidden
  .grid-banner
    position relative
    display grid
    gap 60px
    grid-template-columns 1fr 502px

  .circle
    position absolute
    width 358px
    height 358px
    left 823px
    bottom -123px
    background $primary
    opacity 0.9
    filter blur(600px)
    z-index 0

  .video
    z-index 1
    box-shadow 0 19px 20px 0 #FF660026

  .box-error-50
    display flex
    justify-content center
    align-items center
    background-color $error50
    color $error
    font-size 20px
    padding 12px 60px
    border-radius 8px
    img
      margin-right 12px
    b
      margin-left 4px

  .grid-theme
    display grid
    grid-column-gap 36px
    grid-row-gap 48px
    grid-template-columns repeat(3, 1fr)

  .item-survey
  .item-inquiry
    border-radius 8px
    flex 1
    height 100%
    display flex
    flex-direction column
    align-items center
    justify-content center
    text-align center
    padding 32px

  .item-survey
    background-color $gray3
  .item-inquiry
    background-color $subcolor50

  .button-black
  .button-sub2
    padding 10px 24px
    border-radius 50px
    font-size 15px
    font-weight 700
    color white

  .button-black
    background-color #000
  .button-sub2
    background-color $subcolor2
</style>
